import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import { PictureViewModel } from '@models/pictureTypes';
import useLocales from '@/locales/useLocales';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { AIPortraitShowcaseGender } from './AIPortraitShowcase';
import { useAtom } from 'jotai';

// Define the CategoryTabsProps type
type CategoryTabsProps = {
  activeCategory: string; // Adjust the type as needed
};

const CategoryTabs: React.FC<CategoryTabsProps> = ({ activeCategory }) => {
  const [pictures, setPictures] = useState<PictureViewModel[]>([]);
  const { translate } = useLocales();
  const theme = useTheme();
  const imageRefs = useRef<Map<number, HTMLImageElement | null>>(new Map());
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [gender, setGender] = useAtom(AIPortraitShowcaseGender);

  // Memoize the request parameters to avoid unnecessary re-renders
  const requestParams = useMemo(
    () => ({
      chosenUserId: [1, 5],
      category: activeCategory,
      limit: 150,
      offset: 0,
      random: true,
    }),
    [activeCategory]
  );

  // Fetch pictures only once
  useStateDataRequest(SocketEmits.GetPicturesDataByUserChosenUserId, requestParams, (newPictures) => {
    const typedNewPictures = newPictures as { pictures: PictureViewModel[] }; // Type assertion
    setPictures((prevPictures) => {
      // Only shuffle and update state if pictures have changed
      if (JSON.stringify(prevPictures) !== JSON.stringify(typedNewPictures.pictures)) {
        return typedNewPictures.pictures;
      }
      return prevPictures;
    });
  });

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    const observeImages = () => {
      imageRefs.current.forEach((img) => {
        if (img && inView) {
          const dataSrc = img.getAttribute('data-src');
          if (dataSrc) {
            img.src = dataSrc;
            img.removeAttribute('data-src');
          }
        }
      });
    };

    observeImages();
  }, [inView, pictures]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
      isDown = false;
    };

    const handleMouseDown = (e: MouseEvent) => {
      isDown = true;
      startX = e.pageX - scrollContainer!.offsetLeft;
      scrollLeft = scrollContainer!.scrollLeft;
      scrollContainer!.style.cursor = 'grabbing';
    };

    const handleMouseUp = () => {
      isDown = false;
      scrollContainer!.style.cursor = 'grab';
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown || !isHovering) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer!.offsetLeft;
      const walk = (x - startX) * 2; // Adjust the multiplier for sensitivity
      scrollContainer!.scrollLeft = scrollLeft - walk;
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('mouseenter', handleMouseEnter);
      scrollContainer.addEventListener('mouseleave', handleMouseLeave);
      scrollContainer.addEventListener('mousedown', handleMouseDown);
      scrollContainer.addEventListener('mouseup', handleMouseUp);
      scrollContainer.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
        scrollContainer.removeEventListener('mousedown', handleMouseDown);
        scrollContainer.removeEventListener('mouseup', handleMouseUp);
        scrollContainer.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [pictures, isHovering]);

  return (
    <Box sx={{ marginBottom: isMobile ? theme.spacing(2) : theme.spacing(9), padding: theme.spacing(2) }}>
      <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: isMobile ? 0 : theme.spacing(2) }}>
        <Typography variant={isMobile ? 'h5' : 'h3'} sx={{ fontWeight: 'bold', ml: isMobile ? 0 : 2 }}>
          {translate(activeCategory)}
        </Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} sx={{ ml: 2 }}>
          {translate('home.aiGeneratedPortraits')}
        </Typography>
      </Box>
      <Box
        ref={(node: HTMLDivElement | null) => {
          ref(node);
          scrollRef.current = node;
        }}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          overflowY: 'hidden',
          padding: theme.spacing(1),
          marginLeft: theme.spacing(-2),
          marginRight: theme.spacing(-2),
          gap: theme.spacing(2),
          height: '30vh',
          '&:hover': {
            cursor: 'grab',
          },
          '&:active': {
            cursor: 'grabbing',
          },
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          userSelect: 'none', // Add this line
        }}
      >
        {pictures
          .filter((picture) => {
            if (gender === 'male') {
              return picture.userId === 1;
            } else {
              return picture.userId === 5;
            }
          })
          .map((picture) => (
            <Box
              key={picture.id}
              sx={{
                overflow: 'hidden',
                flex: '0 0 auto',
                borderRadius: 2,
                boxShadow: theme.shadows[3],
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Box sx={{ height: '100%', display: 'block', aspectRatio: '9/7', overflow: 'hidden', maxWidth: '300px' }}>
                <img
                  src="" // Empty src attribute
                  data-src={picture.pictureThumbUrl}
                  alt={picture.id?.toString() ?? ''}
                  draggable="false" // Add this line
                  ref={(el) => {
                    if (el) {
                      imageRefs.current.set(picture.id!, el);
                    }
                  }}
                  style={{ height: '100%', display: 'block', objectFit: 'cover' }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CategoryTabs;
