import { m } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Grid, Button, useMediaQuery, Card, CardContent } from '@mui/material';
import { MotionViewport, varFade } from '../../components/animate';
import useLocales from '@/locales/useLocales';
import { useState } from 'react';
import React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BigHomeButton from '@/components/buttons/homeMobile';
import { PATH_AUTH } from '@/routes/paths';
import { atom, useAtom } from 'jotai';

export const AIPortraitShowcaseGender = atom<'male' | 'female'>('female');

interface GenderSwitchProps {
  gender: 'male' | 'female';
  handleGenderSwitch: (isMale: boolean) => void;
}

const StyledRoot = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(9),
  padding: theme.spacing(5, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
}));

const ImageBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 0,
  paddingTop: '100%', // 1:1 Aspect Ratio
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[20],
  },
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const ResultImage = styled(m.div)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[10],
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[20],
  },
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

export default function AIPortraitShowcase() {
  const { translate } = useLocales();
  const [gender, setGender] = useAtom(AIPortraitShowcaseGender);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleGenderSwitch = (isMale: boolean) => {
    setGender(isMale ? 'male' : 'female');
  };

  return (
    <StyledRoot>
      <Container component={MotionViewport} sx={{ position: 'relative' }}>
        <Description />
        <StyledCard sx={{ background: theme.palette.background.paper }}>
          <CardContent>
            <UploadExamples gender={gender} isMobile={isMobile} />
          </CardContent>
        </StyledCard>
        <StyledCard sx={{ background: theme.palette.background.default }}>
          <CardContent>
            <GenderSwitch gender={gender} handleGenderSwitch={handleGenderSwitch} />
          </CardContent>
        </StyledCard>
        <StyledCard sx={{ background: theme.palette.background.paper }}>
          <CardContent>
            <ResultShowcase gender={gender} isMobile={isMobile} />
          </CardContent>
        </StyledCard>
        <m.div variants={varFade().inUp} style={{ textAlign: 'center', paddingTop: theme.spacing(6) }}>
          <Typography variant={isMobile ? 'h4' : 'h3'} gutterBottom>
            {translate('AIPortraitShowcase.summaryTitle')}
          </Typography>
        </m.div>
        <m.div variants={varFade().inUp}>
          <Typography variant={isMobile ? 'body2' : 'h5'} color="text.secondary" sx={{ fontWeight: 200, textAlign: 'center' }}>
            {translate('AIPortraitShowcase.summaryDescription')}
          </Typography>
        </m.div>

        <Box sx={{ mt: 9, textAlign: 'center' }}>
          <m.div variants={varFade().inUp}>
            <Box
              sx={{
                bgcolor: 'primary.main',
                color: 'common.black',
                py: 2,
                px: 4,
                borderRadius: 2,
                display: 'inline-block',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                maxWidth: 'md',
              }}
            >
              <Typography
                variant={isMobile ? 'h5' : 'h4'}
                sx={{
                  fontWeight: '200',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  letterSpacing: 1.5,
                }}
              >
                {translate('AIPortraitShowcase.freePortraitsForNewCustomers')}
              </Typography>
            </Box>
          </m.div>
          <m.div variants={varFade().inUp} style={{ marginTop: theme.spacing(9) }}>
            <BigHomeButton
              text={translate('AIPortraitShowcase.startYourPerfectPhotoShootNow')}
              url={PATH_AUTH.login}
              fullWidth={false}
              sx={{ py: 3, fontSize: isMobile ? '1.1rem' : '1.25rem' }}
            />
          </m.div>
        </Box>
      </Container>
    </StyledRoot>
  );
}

function Description() {
  const { translate } = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const headerHeight = typeof document !== 'undefined' ? document.getElementById('header-main')?.clientHeight : 0;

  return (
    <>
      <div
        id="AIShowcase"
        style={{ position: 'relative', top: `-${(document.getElementById('header-main')?.clientHeight || 0) + 24}px` }}
      />
      <Stack spacing={3} sx={{ textAlign: 'center', mb: 9 }}>
        <m.div variants={varFade().inDown}>
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            sx={{
              textAlign: 'center',
              letterSpacing: 0.5,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              fontWeight: 200,
            }}
          >
            {translate('AIPortraitShowcase.unleashYourInnerSupermodel')}
          </Typography>
        </m.div>

        <m.div variants={varFade().inDown}>
          <Typography
            variant={isMobile ? 'body1' : 'h5'}
            color="text.secondary"
            sx={{
              textAlign: 'center',
              letterSpacing: 0.2,
              fontWeight: 200,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {translate('AIPortraitShowcase.exploreEndlessHairstyles')}
          </Typography>
        </m.div>
      </Stack>
    </>
  );
}

function GenderSwitch({ gender, handleGenderSwitch }: GenderSwitchProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          variant={gender === 'male' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleGenderSwitch(true)}
          sx={{
            fontSize: isMobile ? '1rem' : '1.2rem',
            fontWeight: 'bold',
            textTransform: 'none',
            px: isMobile ? 2 : 3,
            py: isMobile ? 1 : 1,
          }}
        >
          {translate('AIPortraitShowcase.male')}
        </Button>
        <Button
          variant={gender === 'female' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleGenderSwitch(false)}
          sx={{
            fontSize: isMobile ? '1rem' : '1.2rem',
            fontWeight: 'bold',
            textTransform: 'none',
            px: isMobile ? 2 : 3,
            py: isMobile ? 1 : 1,
          }}
        >
          {translate('AIPortraitShowcase.female')}
        </Button>
      </Stack>
    </Box>
  );
}

function UploadExamples({ gender, isMobile }: { gender: 'male' | 'female'; isMobile: boolean }) {
  const { translate } = useLocales();

  const examples =
    gender === 'male'
      ? [
          '/assets/images/uploadExampleMain/man/1.jpg',
          '/assets/images/uploadExampleMain/man/2.jpg',
          '/assets/images/uploadExampleMain/man/3.jpg',
          '/assets/images/uploadExampleMain/man/4.jpg',
        ]
      : [
          '/assets/images/uploadExampleMain/woman/7.jpg',
          '/assets/images/uploadExampleMain/woman/5.jpg',
          '/assets/images/uploadExampleMain/woman/6.jpg',
          '/assets/images/uploadExampleMain/woman/4.jpg',
        ];

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant={isMobile ? 'h4' : 'h3'} sx={{ textAlign: 'center' }}>
        {translate('AIPortraitShowcase.uploadYourBestPhotos')}
      </Typography>
      <Typography
        variant={isMobile ? 'body1' : 'h5'}
        color="text.secondary"
        sx={{
          textAlign: 'center',
          letterSpacing: 0.2,
          fontWeight: 200,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          mb: 3,
        }}
      >
        {translate('AIPortraitShowcase.uploadBeautifulPicturesOfYourself')}
      </Typography>
      <Grid container spacing={3}>
        {examples.map((src, index) => (
          <Grid item xs={6} sm={6} md={3} key={index}>
            <m.div variants={varFade().inUp}>
              <ImageBox>
                <img src={src} alt={`Example ${index + 1}`} />
              </ImageBox>
            </m.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function ResultShowcase({ gender, isMobile }: { gender: 'male' | 'female'; isMobile: boolean }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const results =
    gender === 'male'
      ? [
          '/assets/images/uploadExampleMain/man/result1.jpg',
          '/assets/images/uploadExampleMain/man/result2.jpg',
          '/assets/images/uploadExampleMain/man/result3.jpg',
          '/assets/images/uploadExampleMain/man/result4.jpg',
        ]
      : [
          '/assets/images/uploadExampleMain/woman/result1.jpg',
          '/assets/images/uploadExampleMain/woman/result2.jpg',
          '/assets/images/uploadExampleMain/woman/result3.jpg',
          '/assets/images/uploadExampleMain/woman/result4.jpg',
        ];

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (imageUrl: string) => {
    if (!isMobile) {
      setSelectedImage(imageUrl);
    }
  };

  const handleModalClose = () => {
    setSelectedImage(null);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant={isMobile ? 'h4' : 'h3'} sx={{ textAlign: 'center' }}>
        {translate('AIPortraitShowcase.resultTitle')}
      </Typography>
      <Typography
        variant={isMobile ? 'body1' : 'h5'}
        color="text.secondary"
        sx={{
          textAlign: 'center',
          letterSpacing: 0.2,
          fontWeight: 200,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          mb: 3,
        }}
      >
        {translate('AIPortraitShowcase.resultDescription')}
      </Typography>
      <Grid container spacing={3}>
        {results.map((src, index) => (
          <Grid item xs={6} sm={6} md={3} key={index}>
            <m.div variants={varFade().inUp}>
              <ResultImage onClick={() => handleImageClick(src)}>
                <img src={src} alt={`Result ${index + 1}`} />
              </ResultImage>
            </m.div>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={!!selectedImage}
        onClose={handleModalClose}
        BackdropProps={{
          onClick: handleModalClose,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <IconButton
              onClick={handleModalClose}
              sx={{
                color: 'text.secondary',
                borderRadius: '50%',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.default',
                },
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <img src={selectedImage || ''} alt="Selected Image" style={{ maxWidth: '100%', maxHeight: '70vh' }} />

          <Typography variant="h6" color="text.secondary" sx={{ mt: 2 }}>
            {translate('AIPortraitShowcase.thisImageIsAIGenerated')}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}
